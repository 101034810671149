<template>
    <div class="add box_b">
        <!--  -->
        <div class="add_main box_b">
            <div class="vue_title">
                <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
                <p> {{id?'修改文章':'添加文章'}}</p>
            </div>
            <!--  -->
            <div class="add_info box_b" v-loading="loading">
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>标题</div>
                    <div class="t_right">
                        <el-input v-model="info.title" size='small' clearable placeholder="请输入活动标题"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>短标题</div>
                    <div class="t_right">
                        <el-input v-model="info.title_short" size='small' maxlength="10" clearable placeholder="请输入短标题"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>封面</div>
                    <div class="t_right">
                        <el-upload class="r_img" accept="image/jpg,image/jpeg,image/png,image/gif" action="" :before-upload="beforeAvatarUpload" :http-request="httpRequestMultiple" :show-file-list="false">
                            <div v-if="info.cover" class="w100 h100">
                                <img class="w100 h100" :src="info.cover" alt="">
                            </div>
                            <div class="r_img_no w100 h100" v-else>
                                <i class="el-icon-plus"></i>
                                <div>上传封面</div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6">专辑</div>
                    <div class="t_right_select">
                        <el-select v-model="info.pid" size='small' clearable placeholder="请选择专辑">
                            <el-option v-for="item in albumList" :key="item.id" :label="item.title" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>分类</div>
                    <div class="t_right_select">
                        <el-select v-model="info.cate_id" size='small' placeholder="请选择分类">
                            <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>对应平台</div>
                    <div class="t_right_select">
                        <el-select v-model="info.platform" size='small' placeholder="请选择对应平台">
                            <el-option v-for="item in platformList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>流量形态</div>
                    <div class="t_right_select">
                        <el-select v-model="info.flow" size='small' placeholder="请选择流量形态">
                            <el-option v-for="item in flowList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>行业类别</div>
                    <div class="t_right_select">
                        <el-select v-model="info.industry" size='small' placeholder="请选择行业类别">
                            <el-option v-for="item in industryList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>是否付费</div>
                    <div class="t_right" style="padding-bottom: 6px;">
                        <el-radio v-model="info.free" :label="1">免费</el-radio>
                        <el-radio v-model="info.free" :label="0">付费</el-radio>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>状态</div>
                    <div class="t_right" style="padding-bottom: 6px;">
                        <el-switch v-model="info.status" :active-value='1' :inactive-value='0'></el-switch>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>文章内容</div>
                    <div class="edit">
                        <div style="border: 1px solid #DCDFE6;">
                            <Toolbar style="width: 100%;border-bottom: 1px solid #DCDFE6" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
                            <Editor style="height: 350px; width: 100%; overflow-y: hidden;" v-model="info.content" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
                        </div>
                    </div>
                </div>

            </div>
            <!--  -->
            <div class="dialog-footer">
                <el-button type="primary" size='small' @click="confirm" :loading="btnLoading">确 定</el-button>
                <el-button size='small' @click="chongzhi">重 置</el-button>
            </div>

        </div>
    </div>
</template>
  
  <script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    name: "notice_add",
    components: {
        Editor,
        Toolbar
    },
    props: {

    },
    data() {
        return {
            headers: {
                'Authorization': this.$store.state.token
            },
            loading: false,
            btnLoading: false,
            info: {
                title: "",
                title_short: "",
                cover: "",
                status: 1,
                cate_id: "",
                platform: "",
                flow: "",
                industry: "",
                free: 1,
                content: "",
                pid: "",
            },
            id: "",
            editor: null,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 配置上传视频
                    uploadVideo: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 继续其他菜单配置...
                }
            },
            mode: 'default', // or 'simple'
            // 
            platformList: [],
            flowList: [],
            industryList: [],
            cateList: [],
            albumList: [],
        };
    },

    created() {
        this.getCate()
        this.getAlbumList()
        this.getParams()
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },

    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        // 获取文章列表  就是专辑列表
        getAlbumList() {
            var that = this
            that.loading = true
            var params = {
                page: 1,
                limit: 1000,
            }
            that.$articleApi.articleList(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    console.log(res.result)
                    if (res.result) {
                        let arr = []
                        res.result.data.forEach(ele => {
                            if (ele.pid == 0) {
                                arr.push(ele)
                            }
                        })
                        that.albumList = arr
                    }
                }
            })
        },
        // 分类
        getCate() {
            var params = {
                page: 1,
                limit: 1000,
            }
            this.$articleApi.categoryList(params).then(res => {
                if (res.code == 1000) {
                    this.cateList = res.result?.data
                }
            })
        },
        getParams() {
            let that = this
            that.$articleApi.articleParams().then(res => {
                if (res.code == 1000) {
                    that.platformList = res.result.platformList    //对应平台
                    that.flowList = res.result.flowList            //流量形态
                    that.industryList = res.result.industryList    //行业类别
                }
            })
        },
        // 富文本上传图片
        update(file, insertFn) {
            var that = this;
            let files = event.target.files[0];
            let form = new FormData();
            form.append("file", files);
            that.$publicApi.uploadFile(form).then(res => {
                if (res.code == 1000) {
                    insertFn(res.result.url, file.name, res.result.url)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        confirm() {
            if (this.info.title == '') {
                this.$errMsg('请输入文章标题')
                return
            }
            if (this.info.title_short == '') {
                this.$errMsg('请输入短标题')
                return
            }
            if (this.info.cover == '') {
                this.$errMsg('请上传文章封面')
                return
            }
            if (this.info.cate_id == '') {
                this.$errMsg('请选择分类')
                return
            }
            if (this.info.platform == '') {
                this.$errMsg('请选择对应平台')
                return
            }
            if (this.info.flow == '') {
                this.$errMsg('请选择流量形态')
                return
            }
            if (this.info.industry == '') {
                this.$errMsg('请选择行业类别')
                return
            }
            if (this.$testVerify.isContent(this.info.content)) {
                this.$errMsg('请输入文章内容')
                return
            }
            // 修改
            if (this.id) {
                this.edit()
            } else {
                // 添加
                this.add()
            }
        },
        // 添加
        add() {
            this.btnLoading = true;
            let obj = JSON.parse(JSON.stringify(this.info))
            if (obj.pid == '') {
                obj.pid = 0
            }
            var params = {
                ...obj
            }
            this.$articleApi.articleAdd(params).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/article/article')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },
        edit() {
            this.btnLoading = true;
            let obj = JSON.parse(JSON.stringify(this.info))
            if (obj.pid == '') {
                obj.pid = 0
            }
            var params = {
                ...obj
            }
            this.$articleApi.articleEdit(params, this.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/article/article')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },

        getInfo() {
            this.loading = true;
            this.$articleApi.articleInfo(this.id).then(res => {
                if (res.code == 1000) {
                    this.info = {
                        title: res.result.title,
                        title_short: res.result.title_short,
                        pid: res.result.pid == 0 ? '' : res.result.pid,
                        cover: res.result.cover,
                        status: res.result.status,
                        cate_id: res.result.cate_id,
                        platform: res.result.platform,
                        flow: res.result.flow,
                        industry: res.result.industry,
                        free: res.result.free,
                        content: res.result.content,
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.$errMsg(res.message)
                }
            })
        },
        chongzhi() {
            this.info = {
                title: "",
                title_short: "",
                cover: "",
                status: 1,
                cate_id: "",
                platform: "",
                flow: "",
                industry: "",
                free: 1,
                pid: "",
                content: "",
            }
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        // 上传图片限制
        beforeAvatarUpload(file) {
            const isLt10KB = file.size / 1024 / 1024 < 10;
            let types = ['image/jpg', 'image/png', 'image/gif', 'image/jpeg'];
            const isType = types.includes(file.type);
            if (!isType) {
                this.$message.error('只能上传只 JPG/PNG/JEPG/GIF 格式!');
            }
            if (!isLt10KB) {
                this.$message.error('上传图片大小不能超过 10M!');
            }

            return isType && isLt10KB;
        },

        // 上传图片
        httpRequestMultiple(uploader) {
            const formData = new FormData();
            formData.set('file', uploader.file);
            this.$publicApi.uploadFile(formData).then(res => {
                if (res.code === 1000) {
                    this.info.cover = res.result.url
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        back() {
            this.$router.back()
        },
    },
}
  </script>
  <style lang="scss" scoped>
// @import "../../assets/style/common.less";

.add {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .add_main {
        width: 100%;
        height: 100%;
    }

    .sms_title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f5fa;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .add_info {
        width: 100%;
        height: calc(100% - 90px);
        padding: 15px 0;
        overflow-y: auto;

        .info_text {
            width: 100%;
            padding: 12px 0 0;
            overflow: hidden;
            display: flex;
            .switch {
                width: 90px;
                text-align: right;
                margin-right: 14px;
            }
            .edit {
                width: 1000px;
                margin-left: 10px;
                padding-top: 6px;
                position: relative;
                z-index: 1000;
            }
            .t_left {
                float: left;
                width: 110px;
                line-height: 32px;
                text-align: right;
            }
            .t_right {
                float: left;
                width: 500px;
                margin-left: 12px;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                .r_img {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    border: 1px solid #e8eaec;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    .r_img_no {
                        padding-top: 20px;
                        font-size: 14px;
                        color: #94979f;
                        i {
                            font-size: 18px;
                        }
                        div {
                            padding-top: 5px;
                        }
                    }
                }
                .r_txt {
                    padding-left: 10px;
                }
                .el-select {
                    width: 100%;
                }
            }
            .t_right_select {
                float: left;
                width: 500px;
                margin-left: 12px;
                .el-select {
                    width: 100%;
                }
            }
            .radio {
                padding-left: 10px;
                display: flex;
                align-items: center;
                height: 32px;
            }
        }
    }
}
.dialog-footer {
    padding-left: 50px;
    padding-bottom: 20px;
}
.box_b {
    box-sizing: border-box;
}
.f_s2 {
    font-size: 14px;
    line-height: 20px;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.color1 {
    color: #e51d41;
}

.color6 {
    color: #606266;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
::v-deep .el-upload {
    width: 100%;
    height: 100%;
}
</style>
  